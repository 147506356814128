<!-- GA doesnt get loaded if we are redirected from My Account, load it manually -->
<head>
  <script>
      window.dataLayer = window.dataLayer || [];
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-N6GDT9');</script>
    <!-- End Google Tag Manager -->
  
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-N6GDT9"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'GTM-N6GDT9', 
        { 'linker': 
          {
            'accept_incoming': true
          }
        }
      ); 
    </script>
</head>
<div class="decom-banner">
  <div *ngIf="showDecomBanner" class="container row flex-nowrap align-items-center p-3">
    <div class="d-flex align-items-center justify-content-center text-white">
      <i (click)="returnToMyAcc()" class="fa fa-lg fa-chevron-left text-white" title="Return" id="return"></i>
    </div>
    <div (click)="returnToMyAcc()" class="w-100 pl-4 pr-5 pt-3 pb-3"><span id="return">Return to My Account Dashboard</span></div>
  </div>
</div>
<de-header [isExternal]="true" [centerLogo]="true" (dukeLogoClick)="goToDecom()"></de-header>
<div class="stepper-container">
    <de-stepper #stepper [steps]="steps" [activeStep]="activeStep" [activeIncrement]="activeIncrement"></de-stepper>
</div>
<div class="account-level-message">
    <div class="account-level-message__text">
        <!-- <p class="account-level-message__text__content">Already enrolled? View your <a (click)="navigateTo('/customer-portal')">Clean Energy Connection Portal</a></p> -->
    </div>
</div>
<router-outlet></router-outlet>
<div class="row justify-content-center pt-5 col-6 offset-3">
    Have a question? Read the Clean Energy Connection <a class="ml-1"
        *ngIf="accountService.getCustomerSegmentId() === customerSegment.MarketRate"
        href="https://desitecoreprod-cd.azureedge.net/_/media/pdfs/for-your-home/220969/cec-def-media-kit-faqs.pdf?la=en&rev=622ea6fd31a14d669c9dec8fd88a9784"
        target="_blank">FAQs</a><a class="ml-1"
        *ngIf="accountService.getCustomerSegmentId() === customerSegment.IncomeQualified"
        href="https://desitecoreprod-cd.azureedge.net/_/media/pdfs/for-your-home/220969/cec-def-media-kit-faqs.pdf?la=en&rev=622ea6fd31a14d669c9dec8fd88a9784"
        target="_blank">FAQs</a>.
</div>
<div class="row justify-content-center p-5 col-6 offset-3"><span class="text-center">
        If you have a question that cannot be answered using the website or FAQs, then please contact us at <a
            class="mr-1 ml-1" href="tel:8662332290">866.233.2290</a> or <a class="ml-1"
            *ngIf="accountService.getCustomerSegmentId() === customerSegment.MarketRate"
            href="mailto: CleanEnergyConnection@duke-energy.com">CleanEnergyConnection@duke-energy.com</a><a
            class="ml-1" *ngIf="accountService.getCustomerSegmentId() === customerSegment.IncomeQualified"
            href="mailto: CECQualified@duke-energy.com">CECQualified@duke-energy.com</a>.</span>
</div>
<de-footer [showSlogan]="false"></de-footer>