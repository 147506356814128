import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeLoginComponent } from '@de-electron/electron-angular-components';
import { ApiService } from 'src/app/services/api.service';
import { AccountService, AuthenticationService } from 'src/app/services';
import { forkJoin } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AllocationStatus, CustomerSegment } from 'src/app/modules/shared/models/enum.model';
import { url } from 'inspector';
import { WindowReferenceService } from 'src/app/services/window-reference.service';
import { AppConfig } from 'src/app/config/app.config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginComponent') loginComponent: DeLoginComponent;
  public listOfAccountInformation = [];
  private customerType = 'other';
  public isLoginAllowed = true;
  public isLoading = false;
  public isCecAuth = false;
  private decomHome;
  constructor(
    public router: Router,
    public api: ApiService,
    public appConfig: AppConfig,
    public authService: AuthenticationService,
    public accountService: AccountService,
    public activeRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private windowRef: WindowReferenceService
  ) {

    this.isCecAuth = activeRoute.snapshot.queryParams['cecAuth'];
    const authToken = activeRoute.snapshot.queryParams['auth_token'];
    // Local storage wants a string, isCecAuth is used as a bool below so we use the param value
    localStorage.setItem('cecAuth', JSON.stringify(this.isCecAuth));
    if (activeRoute.snapshot.queryParams['customer_segment_id'] !== undefined) {
      if ((activeRoute.snapshot.queryParams['customer_segment_id'] == +CustomerSegment.IncomeQualified || activeRoute.snapshot.queryParams['customer_segment_id'] == +CustomerSegment.MarketRate)) {
        this.accountService.setCustomerSegmentId(activeRoute.snapshot.queryParams['customer_segment_id'] + "");
        this.accountService.setIsInRSPMode(false);
        this.accountService.setIsInEditMode(false);
        if (this.activeRoute.snapshot.queryParams['customer_segment_id'] == "1") {
          this.customerType = "income-qualified"
        } else if (this.activeRoute.snapshot.queryParams['customer_segment_id'] == "2") {
          this.customerType = "market-rate"
        }
        // make sure auth token is not undefined
        if(this.isCecAuth && authToken){
          this.isLoading = true;
          localStorage.setItem('deAuthToken', JSON.stringify(authToken));
          this.api.getCecAuthAccounts(authToken, this.isCecAuth).subscribe((res) => {
            this.isLoading = false;
            this.deComLoginUser(res.cdp_id, res.related_accounts);
          });
        }
        this.logLink('page-load');
      }
      else {
        throw new Error();
      }
    } else {
      throw new Error();
    }
  }

  ngOnInit(): void {
    this.decomHome = environment.production ? 'https://duke-energy.com/home' : 'https://sc10qa.duke-energy.com/home';
    this.api.getLoginKillSwitchStatus().subscribe(resp => {
      this.isLoginAllowed = resp.show_login_boxes;
    })
  }

  processLookupResponse(respAccounts) {
    respAccounts.forEach(ra => {
      if (this.listOfAccountInformation.findIndex(loai => { return loai.account_number == ra.account_number }) < 0) {
        this.listOfAccountInformation.push(ra);
      }
    });
    this.accountService.setAccounts(this.listOfAccountInformation);
    if (this.listOfAccountInformation[0].customer_segment.customer_segment_id == CustomerSegment.LocalGovernment || this.listOfAccountInformation[0].customer_segment.customer_segment_id == CustomerSegment.LargeCustomer) {
      this.pushSigninErrorEvent(`cec-${this.customerType}-sign-in-error`, 'Accounts under this profile are not eligible.')
      this.snackBar.open(
        'The accounts under this profile are not eligible for online enrollment, please contact your Duke Energy Account Executive or CleanEnergyConnection@duke-energy.com for further assistance.'
        , 'Continue', { panelClass: 'snackbar' });
    }

    let hasLiveAllocations = false;
    let hasEnrolledAllocations = false;
    this.listOfAccountInformation.forEach(x => {
      x.allocations.forEach(allocation => {
        if (allocation.allocation_status_id == AllocationStatus.Enrolled) {
          hasEnrolledAllocations = true;
          return;
        } else if (allocation.allocation_status_id == AllocationStatus.RSPRegistered || allocation.allocation_status_id == AllocationStatus.PendingEnrollment || allocation.allocation_status_id == AllocationStatus.Waitlist) {
          hasLiveAllocations = true;
        }
      })
    });
    this.authService.setUserRole('cust');
    // Redirect to the Portal, upload page for Income-Qualified Docs, or the list of accounts to manage in Enrollment Tool...
    if (hasEnrolledAllocations) {
      this.router.navigate(['/customer-portal']);
    } else if (this.accountService.getCustomerSegmentId() == CustomerSegment.IncomeQualified && !hasLiveAllocations && (this.listOfAccountInformation.filter(x => {
      return (x.low_income.nes_participant == false && x.low_income.low_income_verification_status !== "VERIFIED")
    }).length > 0)) {
      this.router.navigate(['/enrollment/income-qualified']);
    } else {
      this.router.navigate(['/enrollment/verified-accounts']);
    }
    if(this.isCecAuth){
      // Push GTM Log in Events for CARE
      this.windowRef.nativeWindow.dataLayer.push({'event' : 'cec-web', 'AlternateID': this.listOfAccountInformation[0].alternate_id, 'jurisdiction': this.listOfAccountInformation[0].jurisdiction_code, 'referral':'de-com'});
    }else {
      this.windowRef.nativeWindow.dataLayer.push({'event' : 'cec-web', 'AlternateID': this.listOfAccountInformation[0].alternate_id, 'jurisdiction': this.listOfAccountInformation[0].jurisdiction_code, 'referral': 'cec-direct'});
    }
    localStorage.setItem('jurisdiction', this.listOfAccountInformation[0].jurisdiction_code);
  }

  doLookupCall(subscriptionList) {
    forkJoin(subscriptionList).subscribe(respAccounts => {
      respAccounts.forEach(resp => {
        if ((<any>resp).error && (<any>resp).error.message) {
          this.pushSigninErrorEvent(`cec-${this.customerType}-sign-in-error`, (<any>resp).error.message)
          let snack = this.snackBar.open(
            'The account retrieval process is taking longer than usual. You can press continue to wait, or return at a later time. If this issue persists, please email cleanenergyconnection@duke-energy.com.'
            , 'Continue', { panelClass: 'snackbar' });
          snack.afterDismissed().subscribe(() => {
            this.doLookupCall(subscriptionList);
          });
        }
      })
      this.processLookupResponse(respAccounts);
    }, () => {
      this.pushSigninErrorEvent(`cec-${this.customerType}-sign-in-error`, 'Took longer than usual');
      let snack = this.snackBar.open(
        'The account retrieval process is taking longer than usual. You can press continue to wait, or return at a later time. If this issue persists, please email cleanenergyconnection@duke-energy.com.'
        , 'Continue', { panelClass: 'snackbar' });
      snack.afterDismissed().subscribe(() => {
        this.doLookupCall(subscriptionList);
      });

    })
  }

  deComLoginUser(cdpId: string, relatedAccounts: string[]){
    this.accountService.setCDPId(cdpId);
    let subscriptionList = [];
    if(!relatedAccounts){
      this.isCecAuth = false;
      this.windowRef.nativeWindow.dataLayer.push({ 
        'event-category':'cec_error', 
        'event-action': `No Related Accounts found for Account.`, 
        'event-label':'login-error', 'event':'event-click' 
      });
      this.snackBar.open(
        "No Related Accounts found for Account."
        , 'Ok', { panelClass: 'snackbar' });
      
      this.isLoginAllowed = true;
      throw Error("No Related Accounts found!");
    }
    relatedAccounts.forEach(x => {
      subscriptionList.push(this.api.getCustomerLookup(x, this.accountService.getCustomerSegmentId(), this.accountService.getCDPId()))
    });
    forkJoin(subscriptionList).subscribe(respAccounts => {
      respAccounts.forEach(resp => {
        if ((<any>resp).error && (<any>resp).error.message) {
          this.pushSigninErrorEvent(`cec-${this.customerType}-sign-in-error`, (<any>resp).error.message);
          let snack = this.snackBar.open(
            'The account retrieval process is taking longer than usual. You can press continue to wait, or return at a later time. If this issue persists, please email cleanenergyconnection@duke-energy.com.'
            , 'Continue', { panelClass: 'snackbar' });
          snack.afterDismissed().subscribe(() => {
            this.doLookupCall(subscriptionList);
          });
        }
      })
      this.processLookupResponse(respAccounts);
    }, (() => {
      this.doLookupCall(subscriptionList);
    }));
  }

  loginUser(event) {
    this.api.login(event.username, event.password).subscribe(resp => {
      if (resp.error && resp.error.message) {
        if ((resp.error.message + "").includes('No related accounts') || (resp.error.message + "").includes('My Account login')) {
          this.pushSigninErrorEvent(`cec-${this.customerType}-sign-in-error`, resp.error.message)
          this.snackBar.open(
            resp.error.message
            , 'Ok', { panelClass: 'snackbar' })
            .afterDismissed().subscribe(() => {
              location.reload();
            });
        } else {
          this.pushSigninErrorEvent(`cec-${this.customerType}-sign-in-error`, resp.error.message)
          this.snackBar.open(
            'Uknown error please refresh page and try again'
            , 'Ok', { panelClass: 'snackbar' })
            .afterDismissed().subscribe(() => {
              location.reload();
            });
          ;
        }

        throw Error;
      }
      if (!resp.related_accounts || resp.related_accounts.length == 0) {
        this.pushSigninErrorEvent(`cec-${this.customerType}-sign-in-error`, 'Error: No related accounts found.');
        this.snackBar.open(
          'Error: No related accounts found.'
          , 'Ok', { panelClass: 'snackbar' })
          .afterDismissed().subscribe(() => {
            location.reload();
          });
        throw Error;
      }

      this.accountService.setCDPId(resp.cdp_id);
      let subscriptionList = [];
      resp.related_accounts.forEach(x => {
        subscriptionList.push(this.api.getCustomerLookup(x, this.accountService.getCustomerSegmentId(), this.accountService.getCDPId()))
      });
      forkJoin(subscriptionList).subscribe(respAccounts => {
        respAccounts.forEach(resp => {
          if ((<any>resp).error && (<any>resp).error.message) {
            this.pushSigninErrorEvent(`cec-${this.customerType}-sign-in-error`, (<any>resp).error.message);
            let snack = this.snackBar.open(
              'The account retrieval process is taking longer than usual. You can press continue to wait, or return at a later time. If this issue persists, please email cleanenergyconnection@duke-energy.com.'
              , 'Continue', { panelClass: 'snackbar' });
            snack.afterDismissed().subscribe(() => {
              this.doLookupCall(subscriptionList);
            });
          }
        })

        this.processLookupResponse(respAccounts);
      }, (() => {
        this.doLookupCall(subscriptionList);
      }))
    }, (() => {
      this.pushSigninErrorEvent(`cec-${this.customerType}-sign-in-error`, 'Uknown Error');
      this.snackBar.open(
        'Uknown error please refresh page and try again'
        , 'Ok', { panelClass: 'snackbar' });
    }));
  }





  pushSigninErrorEvent(eventCategory: string, eventAction: string) {
    this.windowRef.nativeWindow.dataLayer.push({
        eventCategory,
        eventAction,
        'event-label': 'clean-energy-connection-enrollment-portal',
        'event': 'event-click'
      })
      this.windowRef.nativeWindow.dataLayer.push({ 'event': 'send-VPV', 'vpv-name': `/vpv/cec/${this.customerType}/sign-in/clean-energy-connection-enrollment-portal/error` })
  }

  enrollAsGuest() {
    if (this.accountService.getCustomerSegmentId() == '1') {
      location.href = ("https://www.duke-energy.com/home/products/clean-energy-connection/incomequalified/enroll?_gl=1*1akltrq*_ga*MTMwNDMwNzg2Mi4xNjU5NDYwMDE2*_ga_HB58MJRNTY*MTY2NzMxNDU0NC45LjEuMTY2NzMxNTA3MS4wLjAuMA..&_ga=2.102087362.2110001083.1667244358-1304307862.1659460016");
    } else {
      location.href = ("https://www.duke-energy.com/home/products/clean-energy-connection/enroll");
    }
  }

  logLink(typeOfLink: string) {
    this.windowRef.nativeWindow.dataLayer.push({ 'event': 'send-VPV', 'vpv-name': `/vpv/cec/${this.customerType}/sign-in/clean-energy-connection-enrollment-portal/${typeOfLink}` })
  }

  goToDecom(){
    window.location.href = this.decomHome;
  }
}
