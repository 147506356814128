import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService, StepperService } from 'src/app/services';
import { AccountService } from 'src/app/services/account.service';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { CustomerSegment } from 'src/app/modules/shared/models/enum.model';
import { WindowReferenceService } from 'src/app/services/window-reference.service';
import { AppConfig } from 'src/app/config/app.config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})
export class EnrollmentComponent implements AfterViewInit, OnInit {
  @ViewChild ('stepper') stepper: ElementRef;

  public get customerSegment(): typeof CustomerSegment {
    return CustomerSegment; 
  }

  public steps;
  public activeStep;
  public activeIncrement;
  public showDecomBanner = false;
  private decomHome;
  private apigeeUrl;

  user: CognitoUserInterface | undefined;
  authState: AuthState | undefined;

  constructor(public stepperService: StepperService,
    public accountService: AccountService,
    public router: Router,
    public appConfig: AppConfig,
    public windowRef: WindowReferenceService,
    private ref: ChangeDetectorRef) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/enrollment/verified-accounts') {
          this.stepperService.setActiveStep(1);
        } else if (event.urlAfterRedirects.includes('/enrollment/subscription-options') ||
          event.urlAfterRedirects.includes('/enrollment/modify-waitlist')) {
          this.stepperService.setActiveStep(2);
        } else if (event.urlAfterRedirects === '/enrollment/terms-and-conditions') {
          this.stepperService.setActiveStep(3);
        } else if (event.urlAfterRedirects === '/enrollment/enrollment-summary') {
          this.stepperService.setActiveStep(4);
        }
      }
    });
  }


  ngOnInit() {
    this.decomHome = environment.production ? 'https://duke-energy.com/home' : 'https://sc10qa.duke-energy.com/home';
    this.apigeeUrl = this.appConfig.getConfig('apigeeUrl');
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
    })

    this.steps = this.stepperService.steps;
    this.stepperService.getActiveStep().subscribe(value => {
      this.activeStep = value;
    });
    this.stepperService.getActiveIncrement().subscribe(value => {
      this.activeIncrement = value;
    });
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  ngAfterViewInit(): void {
    this.showDecomBanner = JSON.parse(localStorage.getItem('cecAuth'));
    this.stepperService.stepper = this.stepper;
  }

  public returnToMyAcc(){
    this.windowRef.nativeWindow.dataLayer.push({
      'event':'send-VPV', 
      'vpv-name':'/vpv/cec/market-rate/login/return-to-my-account-dashboard/clickthrough'}
    );
    this.windowRef.nativeWindow.dataLayer.push({ 
      'event-category': 'cec_sos', 
      'event-action': '/login',
      'event-label': 'return-to-my-account-|-clickthrough',
      'event': 'event-click' 
    });
    const authToken = JSON.parse(localStorage.getItem('deAuthToken'));
    window.location.href = 
    `https://${this.apigeeUrl}/v1/cust/digitalservices/public/token/authorize?scope=EXPERIENCE_SELECTOR&state=Login&response_type=code&redirect_uri=https%3A%2F%2F${this.apigeeUrl}%2Fv1%2Fcust%2Fdigitalservices%2Fpublic%2Fwasp%2Fredirect&access_token=${authToken}`;
  }

  goToDecom(){
    window.location.href = this.decomHome;
  }
}
