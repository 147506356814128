<!-- GA doesnt get loaded if we are redirected from My Account, load it manually -->
<head>
    <script>
        window.dataLayer = window.dataLayer || [];
        (function (w, d, s, l, i) {
          w[l] = w[l] || []; w[l].push({
            'gtm.start':
              new Date().getTime(), event: 'gtm.js'
          }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-N6GDT9');</script>
      <!-- End Google Tag Manager -->
    
      <!-- Global site tag (gtag.js) - Google Analytics -->
      <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-N6GDT9"></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'GTM-N6GDT9', 
          { 'linker': 
            {
              'accept_incoming': true
            }
          }
        ); 
      </script>
</head>
<de-header [isExternal]="true" [centerLogo]="true" (dukeLogoClick)="goToDecom()"></de-header>
<div class="container">
    <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center p-3">
        <h1 class="w-100">Clean Energy Connection<span *ngIf="accountService.getCustomerSegmentId() == '1'">
                Income-Qualified</span> Enrollment Portal</h1>
        <!-- <p>Sign in with your My Account email and password. If you don't have one, you will need to <a (click)="logLink('register')" href="https://p-auth.duke-energy.com/my-account/registration-r5" target="_blank">register</a>.</p>
                <p>Having trouble logging in, please email us at <a href = "mailto: CleanEnergyConnection@duke-energy.com">CleanEnergyConnection@duke-energy.com</a> with your name, address, account number and contact number so we can follow-up with you within 2 business days.</p> -->
        <p>To get started, sign in with your MyAccount email and password. If you don't have one, you
            will
            need
            to <a
                href="https://p-auth.duke-energy.com/my-account/registration-r5?_gl=1*pfs2ge*_ga*MTI2OTA5Mjg0LjE2NjM2ODExNjk.*_ga_HB58MJRNTY*MTY2Mzg1MTE3MS42LjEuMTY2Mzg1MTE4MS4wLjAuMA..&_ga=2.257342859.1450653280.1663681169-126909284.1663681169)"
                target="_blank">register</a>.
        </p>
        <p>If you have questions please email us directly at
            <a *ngIf="accountService.getCustomerSegmentId() == '1'"
                href="mailto: CECQualified@duke-energy.com">CECQualified@duke-energy.com<br></a>
            <a *ngIf="accountService.getCustomerSegmentId() == '2'"
                href="mailto: CleanEnergyConnection@duke-energy.com">CleanEnergyConnection@duke-energy.com<br></a>
            with your name, address, account number and a good contact number so we can follow-up with you
            within 2 business days.
        </p>
        <div class="de-login-wrapper">
            <div *ngIf="isLoading">
                <de-loader></de-loader>
            </div>
            <de-login #loginComponent *ngIf="isLoginAllowed && !isCecAuth" [showRegisterHelpLinks]="false" [loginTitle]="''"
                [usernamePlaceholder]="'Username'" (login)="loginUser($event)"></de-login>
        </div>
        <div *ngIf="!isLoginAllowed" class="login-off-container align-items-center p-5 mb-5 mt-5">
            <i class="fa fa-lg fa-info-circle pr-5" title="Info"></i>
            <h3>Access to your account is currently unavailable due to maintenance. Please try again later. We
                apologize for any inconvenience.</h3>
        </div>
        <div class="text-center">
            <p *ngIf="isLoginAllowed">
                <!-- <a class="pr-5 text-nowrap fs-link" (click)="logLink('forgot-username')"
                    href="https://www.duke-energy.com/forgot-username"
                    target="_blank">Forgot Username</a>
                <a class="pl-5 text-nowrap fs-link" (click)="logLink('forgot-password')"
                    href="https://p-auth.duke-energy.com/my-account/sign-in/forgot-password" target="_blank">Forgot
                    Password</a> -->
                <a class="text-nowrap fs-link" (click)="logLink('forgot-password')"
                    href="https://www.duke-energy.com/need-help-sign-in" target="_blank">
                    Need help signing in? </a>
            </p>
            <p *ngIf="isLoginAllowed">
                Having trouble logging in or would prefer to enroll via a form, enroll as a guest.
            </p>
            <de-button *ngIf="isLoginAllowed && !isCecAuth" (click)="enrollAsGuest()" type="continue">Enroll As Guest</de-button>
        </div>
    </div>
</div>
<de-footer [showSlogan]="true"></de-footer>