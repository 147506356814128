<!-- GA doesnt get loaded if we are redirected from My Account, load it manually -->
<head>
    <script>
        window.dataLayer = window.dataLayer || [];
        (function (w, d, s, l, i) {
          w[l] = w[l] || []; w[l].push({
            'gtm.start':
              new Date().getTime(), event: 'gtm.js'
          }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-N6GDT9');</script>
      <!-- End Google Tag Manager -->
    
      <!-- Global site tag (gtag.js) - Google Analytics -->
      <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-N6GDT9"></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'GTM-N6GDT9', 
          { 'linker': 
            {
              'accept_incoming': true
            }
          }
        ); 
      </script>
  </head>
<!-- Account Dropdown -->
<mat-select [disabled]="accountOptions.length <= -1" class="account-select" [(value)]="selectedAccount" (selectionChange)="onAccountSelectionChange($event)">
    <mat-option *ngFor="let account of accountOptions" [value]="account">
        Account #{{account.account_number}} {{account.premise_address?.line1}}, {{account.premise_address?.city}} {{account.premise_address?.state}}
    </mat-option>
</mat-select>
<diV *ngIf="showBanner && !hasAlertExpireDateElapsed"
     class="banner-container">
  <div class="banner-container__icon">
    <img src="https://p-cd.duke-energy.com/-/media/images/home-redesign-jss/icon-alert-outage.svg?h=31&iar=0&w=36&rev=8bb5ac7e76404db68fd47f" />
  </div>
  <div *ngIf="accountService.getCustomerSegmentId() === '1'" class="banner-container__message">
    {{ customerSegment[0].customer_segment_alert_message }}
    
  </div>
  <div *ngIf="accountService.getCustomerSegmentId() === '2'" class="banner-container__message">
    {{ customerSegment[0].customer_segment_alert_message }}
  </div>
  <div class="banner-container__close">
    <mat-icon (click)="closeBanner()">
      close
    </mat-icon>
  </div>
</diV>
<div class="decom-banner">
    <div *ngIf="showDecomBanner" class="container row flex-nowrap align-items-center p-3">
        <div class="d-flex align-items-center justify-content-center text-white">
        <i (click)="returnToMyAcc()" class="fa fa-lg fa-chevron-left text-white" title="Return" id="return"></i>
        </div>
        <div (click)="returnToMyAcc()" class="w-100 pl-4 pr-5 pt-3 pb-3"><span id="return">Return to My Account Dashboard</span></div>
    </div>
</div>
<div class="trimmed-page-container">
    <!-- Your Solar Subscription Impact -->

    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div> <!--  [ngClass]="{'mobilewidget_solar-impact': isMobile,'widget_solar-impact': !isMobile}" -->
                    <!-- false was used here to hide this area. Remove the ngif when we want to show this section -->
                    <!-- "(impactDataLoading$ | async) === false" -->
                    <ng-container *ngIf="false">
                        <div [ngClass]="{'mobilewidget_solar-impact': isMobile,'widget_solar-impact': !isMobile}">
                        <div class="solar-impact_heading">
                            <div class="solar-impact_heading-title">
                                <p>Your Solar Subscription Impact</p>
                            </div>
                            <div class="solar-impact_heading-buttons">
                                <de-segmented-control
                                    [options]="billingTabOptions"
                                    [selectedOption]="'kWh'"
                                    (optionSelectionChange)="onSelectionChange($event)">
                                </de-segmented-control>
                            </div>
                        </div>
                        <div *ngIf="billImpactData" class="solar-impact_bill-intervals">
                            <app-bill-impact-interval [billImpact]="billImpactData.last_bill" [displayMode]="impactDisplayMode" [title]="'Bill Total'"></app-bill-impact-interval>
                            <app-bill-impact-interval [billImpact]="billImpactData.year_to_date" [displayMode]="impactDisplayMode" [title]="'Year To Date'"></app-bill-impact-interval>
                            <app-bill-impact-interval [billImpact]="billImpactData.lifetime" [displayMode]="impactDisplayMode" [title]="'Lifetime'"></app-bill-impact-interval>
                            <!-- <app-bill-impact-interval [billImpact]="billImpactData.last_bill" [displayMode]="impactDisplayMode"></app-bill-impact-interval>
                            <app-bill-impact-interval [billImpact]="billImpactData.year_to_date" [displayMode]="impactDisplayMode"></app-bill-impact-interval>
                            <app-bill-impact-interval [billImpact]="billImpactData.lifetime" [displayMode]="impactDisplayMode"></app-bill-impact-interval> -->
                        </div>
                        <div class="solar-impact_summations">
                            <div class="solar-impact_monthly-average">
                                <div class="solar-impact_monthly-headings">
                                    <p><b>Monthly Average</b></p>
                                    <p>{{billImpactData?.monthly_average?.bill_interval | number}} Total Months</p>
                                </div>
                                <div class="solar-impact_generation-savings">
                                    <ng-container *ngIf="impactDisplayMode === 'kWh'">
                                        <p class="solar-impact_generation-summation">
                                            {{billImpactData?.monthly_average?.solar_generation_kwh | number : '1.2-2'}} kWh
                                        </p>
                                        <p><b>Solar Generation</b></p>
                                    </ng-container>
                                    <ng-container *ngIf="impactDisplayMode === 'dollars'">
                                        <p class="solar-impact_savings-summation">{{billImpactData?.monthly_average?.savings | currency:'USD'}}</p>
                                        <p style="margin:auto;width: fit-content;color:darkgreen;"><b>Net Savings</b></p>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="solar-impact_usage-break-even">
                                <div class="solar-impact_usage-credits">
                                    <ng-container class="size16" *ngIf="impactDisplayMode === 'kWh'">
                                        <p class="size16">{{billImpactData?.monthly_average?.usage_kwh | number}} kWh</p>
                                        <p><b>Energy Usage</b></p>
                                    </ng-container>
                                    <ng-container *ngIf="impactDisplayMode === 'dollars'">
                                        <div class="breakdown">
                                            <p class="breakdown-label">Fees</p>
                                            <p>{{billImpactData?.monthly_average?.fees | currency:'USD'}}</p>
                                        </div>
                                        <div class="breakdown">
                                            <p class="breakdown-label">Credits</p>
                                            <p>{{billImpactData?.monthly_average?.credits | currency:'USD'}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                                <!-- <div class="solar-impact_break-even">
                                    <p><b>Estimated Break Even</b></p>
                                    <p>{{billImpactData.summary.est_break_even_date}}</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="(impactDataLoading$ | async)">
                        <div class="h-100 w-100">
                            <de-loader></de-loader>
                        </div>
                    </ng-container>
                </div>    
        
                <!-- Edit Subscription -->
                <div [ngClass]="{'mobilewidget_subscription_edit': isMobile,'widget_subscription_edit': !isMobile}" style="font-size:1.21em;">
                    <div class="edit-subscription_heading-title">
                        <p><b>Your Subscriptions</b></p>
                    </div>
                    <br>
                    <div class="edit-subscription_widgets">
                        <table class="allocations_table w-100 pl-1" *ngIf="accountDetail?.allocations?.length > 0">
                            <tr class="row text-size-reg pb-1">
                                <th [ngClass]="{'col-4':hasWaitlistAllocation,'col-4': !hasWaitlistAllocation}" class="col-detail">
                                    <mat-label><b>Subscription</b></mat-label>
                                </th>
                                <th *ngIf="hasWaitlistAllocation" class="col-2 detail">
                                    <mat-label><b>Queue</b></mat-label>
                                </th>
                                <th [ngClass]="{'col-2':hasWaitlistAllocation,'col-3': !hasWaitlistAllocation}" class="col-detail">
                                    <mat-label><b>Status</b></mat-label>
                                </th>
                                <th [ngClass]="{'col-2':hasWaitlistAllocation,'col-3': !hasWaitlistAllocation}" class="col-detail">
                                    <mat-label><b>Status Date</b></mat-label>
                                </th>                                
                                <th *ngIf="!hasWaitlistAllocation" [ngClass]="{'col-1': !hasWaitlistAllocation}" class="col-detail">
                                    <mat-label><b>Increase*</b></mat-label>
                                </th>
                            </tr>
                            <tr *ngFor="let allocation of accountDetail.allocations; let index = index;" class="row text-size-reg pb-1">
                                <td [ngClass]="{'col-4':hasWaitlistAllocation,'col-4': !hasWaitlistAllocation}" class="detail">
                                    <span>{{allocation.allocation_size_kw}} kW </span><span *ngIf="allocation.max_sub_kw">({{allocation.max_sub_kw}} Max)</span>
                                </td>
                                <td *ngIf="hasWaitlistAllocation" class="col-2 detail">
                                    <span *ngIf="allocation.allocation_status_id == 3">{{accountDetail.wait_list_position}}</span>
                                </td>
                                <td [ngClass]="{'col-2':hasWaitlistAllocation,'col-3': !hasWaitlistAllocation}" class="detail">
                                    <span>{{allocation.allocation_status_description}}</span>
                                </td>
                                <td [ngClass]="{'col-2':hasWaitlistAllocation,'col-3': !hasWaitlistAllocation}" class="detail">
                                    <span>{{dateToShow(allocation)| date :'shortDate'}}</span>
                                </td>                                
                                <td *ngIf="!hasWaitlistAllocation" class="rowcol-1 detail">
                                    <span>{{nextIncreaseDateToShow(allocation)| date :'shortDate'}} <BR> ({{allocation.max_sub_kw - allocation.allocation_size_kw}} kW) </span> <!-- ({{allocation.max_sub_kw - allocation.allocation_size_kw}} Kw) -->
                                </td>
                                <td class="col-auto detail options_column">
                                    <span *ngIf="allocation.allocation_status_id != 0 && allocation.allocation_status_id != 2 && allocation.allocation_status_id != 6 && hasYearPassed(allocation.active_date, 12) && 
                                    customerSegment[0].customer_segment_id === '1'">
                                        <a href="javascript:;"
                                        (click)='onEditSubscription(index);'>Edit</a></span>&nbsp;
                                    <span *ngIf="allocation.allocation_status_id != 0 && allocation.allocation_status_id != 2 && allocation.allocation_status_id != 6 && customerSegment[0].customer_segment_id != '1'">
                                        <a href="javascript:;"
                                        (click)='onEditSubscription(index);'>Edit</a></span>&nbsp;
                                    <span *ngIf="allocation.allocation_status_id != 0 && allocation.allocation_status_id != 6" ><a href="javascript:;"
                                        (click)='onCancelSubscription(index);'>Cancel</a></span>
                                </td>
                            </tr><BR>
                            <tr>
                                <p> * Eligibility Date and Amount for Increase</p>
                            </tr>
                        </table>
                    </div>
                </div>

                <!-- Subscription Info -->
                <div class="subscriptionInfo">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3" style="text-align:left;border-right: 2px solid #A7E1EA;">
                                Your Subscription
                            </div>
                            <div class="col-lg-5" style="text-align:left;">
                                <BR><BR><BR>
                                <h1 class="total-sub"> {{billImpactData?.last_bill?.solar_generation_kwh | number}} <span style="font-size:30px;">kWh</span></h1>
                            </div>
                            <div class="col-lg-4">
                                <p class="bolder-sub-text">
                                    Solar Energy Generated Last Month from Your Clean Energy Connection Subscription
                                </p>
                                <p class="sub-text">
                                    Your subscription has generated this many kilowatt-hours of clean renewable solar energy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="yearlyStats">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3">
                                <p class="table-col-text">Year to Date Generation kWh</p>
                                <p class="data-text">
                                    {{billImpactData?.year_to_date?.solar_generation_kwh | number}}
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <p class="table-col-text">Year to Date Subscription Fees</p>
                                <p class="data-text">
                                    {{billImpactData?.year_to_date?.fees | currency:'USD'}}
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <p class="table-col-text">Year to Date Credits Earned</p>
                                <p class="data-text">
                                    {{billImpactData?.year_to_date?.credits | currency:'USD'}}
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <p class="table-col-text">YTD* Net Cost (-) / Savings (+) </p>
                                <p class="data-text">
                                    {{billImpactData?.year_to_date?.savings | currency:'USD'}}
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <p class="table-col-text">Lifetime Generation kWh</p>
                                <p class="data-text">
                                    {{billImpactData?.lifetime?.solar_generation_kwh | number}}
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <p class="table-col-text">Lifetime Subscription Fees</p>
                                <p class="data-text">
                                    {{billImpactData?.lifetime?.fees | currency:'USD'}}
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <p class="table-col-text">Lifetime Credits Earned</p>
                                <p class="data-text">
                                    {{billImpactData?.lifetime?.credits | currency:'USD'}}
                                </p>
                            </div>
                            <div class="col-lg-3">
                                
                                <p class="table-col-text">Lifetime Net Cost / Savings </p>
                                <p class="data-text">
                                    {{billImpactData?.lifetime?.savings | currency:'USD'}}
                                </p>
                            </div>                            
                        </div>
                        <div class="row">
                            <p class="table-disclaimer-text">*1/1/2023 to prior month period</p>
                        </div>
                    </div>
                </div>

                <!-- Monthly Billing Table -->

                <div class="event-table">
                    <div>
                        <!-- <img class="document-icon-table" src="./../../../../../assets/monthly-historical-billing-icon.PNG"> -->
                        <img class="document-icon-table" src="./../../../../../assets/doc-subscription.png">
                    </div>
                    <h2 class="header">Subscription Details </h2>
                    <p>Dive into the details of your Clean Energy Connection Subscription</p>
                    <button (click)="exportExcel()" class="exportData">
                        Export Data
                    </button>
                    <mat-table
                      [dataSource]="histBillingItemsDataSource"
                      class="p-16 mat-elevation-z8"
                    >
                      <!-- Bill Date Column -->
                      <ng-container matColumnDef="bill_interval">
                        <th mat-header-cell *matHeaderCellDef class="text-blue py-20 col-space">Bill Date</th>
                        <td mat-cell *matCellDef="let element" class="py-16 row-space">
                          <!-- <div> 06/19/2023 </div>  " -->
                          <div>{{ convertBillingDate(element) | date: "MM/dd/yyyy" }}</div>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="solar_generation_kwh">
                        <th mat-header-cell *matHeaderCellDef class="text-blue py-20 col-space">
                          Generation (kWh)
                        </th>
                        <td mat-cell *matCellDef="let element" class="py-16 row-space">
                          <div>{{ element.cec_credit_kwh | number }}</div>
                        </td>
                      </ng-container>
                      
                      <ng-container matColumnDef="credits">
                        <th mat-header-cell *matHeaderCellDef class="text-blue py-20 col-space">
                          Credits Earned
                        </th>
                        <td mat-cell *matCellDef="let element" class="py-16 row-space">
                          <div>{{ element.cec_credit || 0 | currency:'USD' }}</div>
                        </td>
                      </ng-container>
                
                      <ng-container matColumnDef="fees">
                        <th mat-header-cell *matHeaderCellDef class="text-blue py-20 col-space">
                          Subscription Fees
                        </th>
                        <td mat-cell *matCellDef="let element" class="py-16 row-space">
                          <div>{{ element.cec_fee || 0 | currency:'USD' }}</div>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="savings">
                        <th mat-header-cell *matHeaderCellDef class="text-blue py-20">
                            Net Cost (-) / Savings (+)
                        </th>
                        <td mat-cell *matCellDef="let element" class="py-16">
                          <div>{{ ((element.cec_credit || 0) - (element.cec_fee || 0)) | currency:'USD' }}</div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                    </mat-table>
                </div>         

            </div>

            <div class="col-lg-4">

                <div clas="row">
                    <div id="request-widget" class="col-lg-12 col-md-12 col-sm-12">
                    
                        <div [ngClass]="{'mobilewidget_request': isMobile,'widget_request': !isMobile}">
                            <a href="https://www.duke-energy.com/home/products/clean-energy-connection/yard-sign-window-sticker" target="_blank">
                                <div class="">
                                    <img style="width:100%;height:100%;" src="./../../../../../assets/request-window-sticker.jpg">
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div clas="row">
                    <div id="request-widget" class="col-lg-12 col-md-12 col-sm-12">
                        <div [ngClass]="{'mobilewidget_request': isMobile,'widget_request': !isMobile}">
                            <a href="https://www.duke-energy.com/_/media/PDFs/Unindexed/223561-CEC-DEF-Retention-Campaign-Annual-Report.pdf" target="_blank">
                                <div class="">
                                    <img style="width:100%;height:100%;" src="./../../../../../assets/cec-2022-annual-report.png">
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div id="documents-widget" class="col-lg-12 col-md-12 col-sm-12">
                        <!-- Documents Widget -->
                        <div [ngClass]="{'mobilewidget_documents': isMobile,'widget_documents': !isMobile}">
                            <div class="documents-icon-wrapper">
                                <img src="./../../../../../assets/doc-icon.png">
                            </div>
                            <div style="text-align:center;" class="documents-page-link">
                                <p (click)="navigateTo('/customer-portal/documents')">View Documents</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="table-wrapper col-lg-12 col-md-12 col-sm-12">
                        <div class="edit-subscription_heading-title">
                            <p><b>Your Alerts</b></p>
                        </div>
                    <div [ngClass]="{'mobilewidget_alerts': isMobile,'widget_alerts': !isMobile}">
                        <table class="account-table">
                            <div class="no-gutters header-row row">
                                <div class="col-4 header-text pl-4">
                                    <b>Date</b>
                                </div>
                                <div class="col-4 header-text"><b>Title</b></div>
                                <div class="col-4 header-text"><b>Message</b></div>
                            </div>
                            <ng-container *ngFor="let alert of alerts; let i = index">
                                <div class="no-gutters account-row row" (click)="onAlertSelection(alert.created_date, alert.alert_type, alert.alert_message)">
                                    <div class="col d-flex flex-row">
                                        <p style="padding-left: 10px;">{{alert.created_at | date }}</p>
                                    </div>
                                    <div class="col"><b>{{alert.alert_type | titlecase }} Alert</b></div>
                                    <div class="col">{{alert.alert_message | titlecase}}</div>
                                </div>
                            </ng-container>
                        </table>
                    </div>
                    </div>
                </div> -->

            </div>

        </div>

        <BR><BR>

        <div class="row" style="background:white;">
            <div class="col-lg-12">
                <h1 style="text-align:center;padding-top:1%;"> Your Subscription Based Generation and Billed Usage</h1>

                <ngx-charts-bar-vertical-2d
                    [view]="view"
                    [scheme]="colorScheme"
                    [results]="this.historicalBillingChartData"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [legend]="showLegend"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel"
                    (select)="onSelect($event)">
                </ngx-charts-bar-vertical-2d><BR>               

            </div>
            <BR><BR>
        </div>
        <div class="row" style="background:white;">
            <div class="col-lg-12">
                <h2 class="chart-disclaimer-header">Understanding the Subscription Based Generation and Billed Usage Chart</h2>
                <p class="chart-disclaimer-text">
                    Each month, Duke Energy's solar centers generate millions of kilowatt-hours (kWh) of clean, sun-powered energy, 
                    and your subscription represents a share of that energy. 
                    The amount of generation will vary each month because the amount of solar energy we generate changes from day to day 
                    based on a number of factors. For example, sunny days generate more energy than cloudy, rainy days, just as the 
                    longer days of summer generate more energy than the shorter days of winter. Unforeseen events like storm damage 
                    can also impact total generation. This data is in the CEC Subscription Generation column.
                </p>
            </div>            
        </div>

    </div>

</div>

     <!-- Alerts -->
        <!-- <div *ngIf="false" [ngClass]="{'mobilewidget_alerts': isMobile,'widget_alerts': !isMobile}">
            <app-dashboard-alert class="no-top-margin"></app-dashboard-alert>
        </div> -->

    <!-- Email Settings -->
    <!-- <div class="widget_email-settings">
        <div class="email-settings_heading-title">
            <p><b>Email Settings</b></p>
        </div>
        <div class="email-settings_email-updates">
            <div class="email-settings_email-updates-heading">
                <p>Receive Email Updates?</p>
            </div>
            <div class="email-settings_email-updates-toggle">
                <mat-slide-toggle #emailUpdatesToggle
                    [checked]="emailSelectsChecked.toggleSwitch"
                    (change)="onEmailInput('toggleSwitch', emailUpdatesToggle._checked)">
                </mat-slide-toggle>
            </div>
        </div>
        <div class="email-settings_options-container">
            <de-radio-button label="Monthly" [checked]="emailSelectsChecked.monthly" (click)="onEmailInput('monthly', true)"></de-radio-button>
            <de-radio-button label="Quarterly" [checked]="emailSelectsChecked.quarterly" (click)="onEmailInput('quarterly', true)"></de-radio-button>
            <de-radio-button label="Yearly" [checked]="emailSelectsChecked.yearly" (click)="onEmailInput('yearly', true)"></de-radio-button>
        </div>
        <div class="email-settings_button-container">
            <de-button type="submit" (click)= "emailSettingsEqualDefault ? $event.preventDefault() : saveEmailSettings()"
            [loading]="emailSaveButtonLoading"
            [disabled]="emailSettingsEqualDefault">SAVE</de-button>
        </div>
    </div> -->

    <!-- Documents Widget -->
    <!-- <div [ngClass]="{'mobilewidget_documents': isMobile,'widget_documents': !isMobile}">
        <div class="documents-icon-wrapper">
            <img src="./../../../../../assets/doc-icon.png">
        </div>
        <div class="documents-page-link">
            <p (click)="onViewDocuments()">View Documents</p>
        </div>
    </div> -->

    <!-- Solar Production/Billing Data -->
    <!-- <div class="widget_solar-production">
        <div class="solar-production_heading-title">
            <p><b>Billing Data</b></p>
        </div>
        <div class="solar-production_selection-portions">
            <de-segmented-control #graphTimelineSelector
                    [options]="solarProdTimeTabOptions"
                    [selectedOption]="'lifetime'"
                    (optionSelectionChange)="onGraphTimelineSelectionChange($event)">
            </de-segmented-control>
            <de-segmented-control #graphDisplaySelector
                    [options]="billingTabOptions"
                    [selectedOption]="'kWh'"
                    (optionSelectionChange)="onGraphDisplaySelectionChange($event)">
            </de-segmented-control>
        </div>
        <div class="solar-production_chart-container" (resized)="resizeGraph($event)">
            <ngx-charts-area-chart #solarGraph
                [view]="graphView"
                [scheme]="colorScheme"
                [showYAxisLabel]="true"
                [xAxis]="true"
                [yAxis]="true"
                [yAxisLabel]="yAxisLabel"
                [gradient]="true"
                [animations]="false"
                [curve]="curve"
                [results]="graphData"
                [legend]="true"
                [legendPosition]="'below'">

            </ngx-charts-area-chart>
        </div>
        <div class="view-monthly-breakdown row pt-3">
            <a href="javascript:;" (click)="navigateTo('/customer-portal/bills')">View Solar Savings Data</a>
        </div>
    </div> -->
