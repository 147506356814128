<!-- GA doesnt get loaded if we are redirected from My Account, load it manually -->
<head>
  <script>
      window.dataLayer = window.dataLayer || [];
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-N6GDT9');</script>
    <!-- End Google Tag Manager -->
  
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-N6GDT9"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'GTM-N6GDT9', 
        { 'linker': 
          {
            'accept_incoming': true
          }
        }
      ); 
    </script>
</head>
<de-header [siteTitle]="siteTitle" (dukeLogoClick)="goToDecom()" [headerLinks]="headerLinks" [isExternal]="true" (navigation)="onHeaderNav($event)">
  <div [style.display]="'hidden'">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" data-name="Layer 1"
      viewBox="0 0 127.07 117.21" id="homeIconLink">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }

          .cls-2 {
            fill: #fff;
          }

          .cls-3 {
            clip-path: url(#clip-path);
          }

          .cls-4 {
            fill: #00789e;
          }
        </style>
        <clipPath id="clip-path">
          <path class="cls-1"
            d="M56,105.52H36.2a3.07,3.07,0,0,1-3-3.1V61.19H24.67a2.66,2.66,0,0,1-1.93-1.72,2.82,2.82,0,0,1,.4-2.59l39.17-40a4.21,4.21,0,0,1,6-.13.8.8,0,0,1,.13.13L80.29,29V24.44a3,3,0,0,1,2.93-3H88a3,3,0,0,1,2.93,3h0V39.83l16.6,17.05a2.82,2.82,0,0,1,.36,2.58A2.67,2.67,0,0,1,106,61.18H97.36v41.24a3.05,3.05,0,0,1-.87,2.14,2.89,2.89,0,0,1-2.09.88H75a3,3,0,0,1-2.93-3h0V83.25a3,3,0,0,0-2.93-3H61.74a3,3,0,0,0-2.92,3h0v19.17a2.93,2.93,0,0,1-.74,2.18,2.73,2.73,0,0,1-2,.92Z" />
        </clipPath>
      </defs>
      <g id="Group_147" data-name="Group 147">
        <path id="Mask-2" class="cls-2"
          d="M56,105.52H36.2a3.07,3.07,0,0,1-3-3.1V61.19H24.67a2.66,2.66,0,0,1-1.93-1.72,2.82,2.82,0,0,1,.4-2.59l39.17-40a4.21,4.21,0,0,1,6-.13.8.8,0,0,1,.13.13L80.29,29V24.44a3,3,0,0,1,2.93-3H88a3,3,0,0,1,2.93,3h0V39.83l16.6,17.05a2.82,2.82,0,0,1,.36,2.58A2.67,2.67,0,0,1,106,61.18H97.36v41.24a3.05,3.05,0,0,1-.87,2.14,2.89,2.89,0,0,1-2.09.88H75a3,3,0,0,1-2.93-3h0V83.25a3,3,0,0,0-2.93-3H61.74a3,3,0,0,0-2.92,3h0v19.17a2.93,2.93,0,0,1-.74,2.18,2.73,2.73,0,0,1-2,.92Z" />
        <g class="cls-3">
          <g id="Mask_Group_147" data-name="Mask Group 147">
            <g id="Icon_Color" data-name="Icon Color">
              <rect id="Icon_Colors_teal-dark-x_background" data-name="Icon Colors teal-dark-x background" class="cls-4"
                x="21.42" y="15.56" width="87.83" height="89.96" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</de-header>
<div *ngIf="loggedIn">
  <router-outlet></router-outlet>
</div>
<div class="w-100" *ngIf="!loggedIn">
  <app-login></app-login>
</div>
<de-footer [showSlogan]="false"></de-footer>