import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services';
import _ from 'lodash';
import { Router } from '@angular/router';
import { CustomerDetail } from '../../models/data/account-detail.model';
import { CustomerSegment } from 'src/app/modules/shared/models/enum.model';

@Component({
  selector: 'app-enrollment-summary',
  templateUrl: './enrollment-summary.component.html',
  styleUrls: ['./enrollment-summary.component.scss']
})
export class EnrollmentSummaryComponent implements OnInit {
  public detailedAccount: CustomerDetail;
  public responseData: any;
  public textToShow = "";
  public dataLayer = window['dataLayer'] || {};
  public get customerSegment(): typeof CustomerSegment {
    return CustomerSegment; 
  }
  private isCecAuth;
  private altId;
  private jurisdiction;

  constructor(public accountService: AccountService,
    public router: Router) {
      this.responseData = this.router.getCurrentNavigation().extras.state.responseData;
     }

  ngOnInit() {
    this.isCecAuth = JSON.parse(localStorage.getItem('cecAuth'));
    this.altId = localStorage.getItem('selectedAltId')
    this.jurisdiction = localStorage.getItem('jurisdiction');
    this.detailedAccount = this.accountService.getSelectedAccount();
    this.textToShow = 'Clean Energy Connection request';
    if (!this.accountService.getIsInEditMode()) {
      if (this.accountService.getIsInRSPMode()) {
        this.textToShow = 'random selection process (RSP) entry';
      } else {
        this.textToShow = 'Clean Energy Connection waitlist entry';
      }
    }

    this.dataLayer = window['dataLayer'] || {};
    const customerSegment = this.accountService.getCustomerSegmentId();
    const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
    if (this.dataLayer) {
      this.dataLayer.push({'event':'send-VPV', 'vpv-name':`/vpv/cec/${registrationType}/${this.accountService.getIsInEditMode() ? 'enrollment-modify' : 'enrollment'}/summary/confirmation`});
      if(this.isCecAuth){
        // Push GTM Events for CARE
        this.dataLayer.push({'event' : 'cec-web', 'AlternateID': this.altId, 'jurisdiction': this.jurisdiction, 'referral':'de-com'});
      }else {
        this.dataLayer.push({'event' : 'cec-web', 'AlternateID': this.altId, 'jurisdiction': this.jurisdiction, 'referral': 'cec-direct'});
      }
    }

  }

  addAnother() {
    const customerSegment = this.accountService.getCustomerSegmentId();
    const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
    if (this.dataLayer) {
      this.dataLayer.push({'event':'send-VPV', 'vpv-name':`/vpv/cec/${registrationType}/enrollment/summary/add-another`})
    }
    setTimeout(x => {
      this.router.navigate(['/enrollment/verified-accounts']);;
    },300)
  }

  
}
