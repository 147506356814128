<div class="banner-container">
  <div class="container">
    <div *ngIf="showBanner" class="row flex-nowrap align-items-center p-3">
      <div class="d-flex align-items-center justify-content-center text-blue">
        <i class="fa fa-lg fa-info-circle pr-2" title="Notice"></i> Notice
      </div>
      <div *ngIf="accountService.getCustomerSegmentId() === '1'" class="w-100 pl-5 pr-5 pt-3 pb-3">
        This is the registration for Income-Qualified Clean Energy Connection. To return to the main program page,
        please
        click <a (click)="switchToStandardMode()">here.</a>
      </div>
      <div *ngIf="accountService.getCustomerSegmentId() === '2'" class="w-100 pl-5 pr-5 pt-3 pb-3">
        This is the registration for Clean Energy Connection. For the income-qualified program, please click <a
          (click)="switchToIncomeMode()">here</a>.
      </div>
      <div class="pl-md-5 pl-sm-2">
        <i class="fa fa-lg fa-times" title="Close" (click)="closeBanner()"></i>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
