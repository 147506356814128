import { Component, ChangeDetectorRef, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { filter } from 'rxjs/operators';
import { AccountService, ApiService } from './services';
import { WindowReferenceService } from './services/window-reference.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  currentRoute = '';
  showBanner = true;
  showDecomBanner = false;
  title = 'Clean Energy Connection Enrollment';
  user: CognitoUserInterface | undefined;
  authState: AuthState | undefined;
  footerLinks = [
    {display: 'Hyperlink', href: 'http://www.google.com'},
    {display: 'Hyperlink2', href: '#!'},
    {display: 'Very very long hyperlink text', href: '#!'}
  ];
  constructor(private ref: ChangeDetectorRef, public accountService: AccountService, private apiService: ApiService, private router: Router, private windowRef: WindowReferenceService) {}
  ngOnInit() {
    this.windowRef.nativeWindow.dataLayer.push({
      'event':'send-VPV', 
      'vpv-name':'/vpv/cec/market-rate/login/return-to-my-account-dashboard/impression'}
    );
    this.windowRef.nativeWindow.dataLayer.push({ 
      'event-category': 'cec_sos', 
      'event-action': '/login',
      'event-label': 'return-to-my-account-|-impression',
      'event': 'event-click' 
    });
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
    })
  }

  ngAfterViewInit(): void {
    this.showDecomBanner = JSON.parse(localStorage.getItem('cecAuth'));
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => this.evaluateIncomeQualifiedBanner(event));
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  public closeBanner(): void {
    this.showBanner = false;
    window.sessionStorage.setItem('low_income_banner', 'dismissed');
  }

  public switchToStandardMode(): void {
    window.sessionStorage.removeItem("low_income_banner");
    location.replace(`/login?customer_segment_id=2`); 
    this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':'/vpv/cec/income-qualified/notice/market-rate-clickthru'});
  }

  public switchToIncomeMode() {
    window.sessionStorage.removeItem("low_income_banner");
    location.replace(`/login?customer_segment_id=1`);
    this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':'/vpv/cec/market-rate/notice/income-qualified-clickthru'});
  }

  private evaluateIncomeQualifiedBanner(event: any): void {
    const allowBanner = (window.sessionStorage.getItem("low_income_banner") ?? "") !== "dismissed";
    this.currentRoute = event.url;
    const isEnrollRoute = this.currentRoute.includes("login") || this.currentRoute.includes("enrollment");
    this.showBanner = allowBanner && isEnrollRoute;
  }
}
