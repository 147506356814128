<!-- GA doesnt get loaded if we are redirected from My Account, load it manually -->
<head>
    <script>
        window.dataLayer = window.dataLayer || [];
        (function (w, d, s, l, i) {
          w[l] = w[l] || []; w[l].push({
            'gtm.start':
              new Date().getTime(), event: 'gtm.js'
          }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-N6GDT9');</script>
      <!-- End Google Tag Manager -->
    
      <!-- Global site tag (gtag.js) - Google Analytics -->
      <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-N6GDT9"></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'GTM-N6GDT9', 
          { 'linker': 
            {
              'accept_incoming': true
            }
          }
        ); 
      </script>
  </head>
<div class="container">
    <div class="step-description">
        <i class="fas fa-3x fa-check-circle color-green"></i>
        <h1 class="gothFont color-green">Thank you for your enrollment request!</h1>
        <p class="gothFont" *ngIf="accountService.getIsInRSPMode()"><b>You will receive a confirmation email about your
                registration at the conclusion of the RSP process.</b></p>
        <p class="gothFont" *ngIf="!accountService.getIsInRSPMode()"><b>You will receive a confirmation email when you
                are processed of the wait list and into program enrollment.</b></p>
        <p class="gothFont" *ngIf="accountService.getCustomerSegmentId() == customerSegment.IncomeQualified"><b>Note: If
                we require more information to validate your submitted documents for the income-qualified program,
                additional instructions will be emailed to you.</b></p>
        <p class="gothFont"><b>Below are the account details for your entry.</b></p>
    </div>
    <div class="table-wrapper gothFont">
        <table class="account-table">
            <div class="no-gutters header-row">
                <div class="col header-text">Account Number</div>
                <div class="col header-text">Service Address</div>
                <div class="col header-text">City</div>
                <div class="col header-text">Subscription Requested</div>
                <div class="col header-text">Status</div>
            </div>
            <ng-container>
                <ng-container *ngFor="let allocation of responseData.allocations; let i = index">
                    <div class="no-gutters account-row">
                        <div class="col d-flex flex-row">
                            <p style="padding-left: 10px;">{{detailedAccount.account_number}}</p>
                        </div>
                        <div class="col">{{detailedAccount.premise_address.line1 | titlecase }}</div>
                        <div class="col">{{detailedAccount.premise_address.city | titlecase}}</div>
                        <div class="col">{{allocation.allocation_size_kw}}kW</div>
                        <div class="col">{{allocation.allocation_status_description | titlecase}}</div>
                    </div>
                </ng-container>
            </ng-container>
        </table>
    </div>
    <div class="text-center gothFont">
        <de-button type="continue" (click)="addAnother()">ADD ANOTHER</de-button>
    </div>
    <div class="documents-container gothFont">
        <div class="documents-heading step-description">
            <h1>Clean Energy Connection Documents</h1>
            These are your accepted program details and documents. Click the documents to review any time.
        </div>
        <app-document-table></app-document-table>
    </div>
</div>